'use client';

import {forwardRef} from 'react';
import {Button, type PressEvent} from 'react-aria-components';
import FastLink from './FastLink';

type Props = {
  children: React.ReactNode;
  href?: string;
  scroll?: boolean;
  onPress?: (e: PressEvent) => void;
  ref?: any;
  className?: string;
  style?: React.CSSProperties;
  prefetch?: boolean;
  type?: 'button' | 'submit';
  disabled?: boolean;
  target?: string;
  tabIndex?: number;
  autoFocus?: boolean;
};

const ButtonComponentWrapper = forwardRef<any, Props>((props, ref) => {
  if (props.href) return <FastLink {...props} ref={ref} />;

  return <Button {...props} ref={ref} />;
});

export default ButtonComponentWrapper;
