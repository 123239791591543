import {twMerge} from 'tailwind-merge';
import type {TablerIcon} from '@tabler/icons-react';
import {type ForwardedRef, forwardRef} from 'react';
import type {PressEvent} from 'react-aria-components';
import ButtonComponentWrapper from './ButtonComponentWrapper';
import SmallLoader from './common/SmallLoader';
import RemixIcon from './RemixIcon';

const getVariantClassNames = (variant: string | undefined) => {
  switch (variant) {
    case 'text':
      return 'bg-transparent hover:bg-(--button-bg-hover) data-pressed:bg-(--button-bg-active) text-(--text-color)';
    case 'secondary':
      return 'secondary-button text-(--text-color)';
    case 'translucent':
      return 'btn-border dark:shadow-[inset_0_0_0_1px_rgb(245_225_255_/_0.12)] backdrop-blur-sm bg-[rgb(255_255_255_/_0.8)] dark:bg-[rgb(255_255_255_/_0.12)] hover:bg-[rgb(255_255_255_/_0.9)] dark:hover:bg-[rgb(255_255_255_/_0.2)] data-pressed:brightness-[0.98] text-(--text-color)';
    case 'primary':
      return 'primary-button-shadow rounded-full bg-(--purple) hover:brightness-110 data-pressed:brightness-125 text-white';
    default:
      return '';
  }
};

const getSizeClassNames = (size: string | undefined) => {
  switch (size) {
    case 'large':
      return 'text-xl px-6 h-12 max-lg:px-5 max-lg:text-lg max-lg:h-11';
    case 'small':
      return 'px-4 h-9';
    default:
      return 'px-4 h-[42px]';
  }
};

type Props = {
  children?: React.ReactNode;
  label?: string;
  className?: string;
  icon?: any;
  TablerIcon?: TablerIcon;
  iconSize?: number;
  color?: string;
  style?: React.CSSProperties;
  buttonVariant?: 'text' | 'primary' | 'secondary' | 'translucent';
  size?: 'large' | 'small';
  onPress?: (e: PressEvent) => void;
  href?: string;
  scroll?: boolean;
  prefetch?: boolean;
  'aria-label'?: string;
  type?: 'button' | 'submit';
  disabled?: boolean;
  isLoading?: boolean;
  target?: string;
  tabIndex?: number;
  ref?: ForwardedRef<HTMLAnchorElement | HTMLButtonElement>;
  autoFocus?: boolean;
};

const BaseButton = forwardRef<HTMLButtonElement | HTMLAnchorElement, Props>(
  (
    {
      children,
      label,
      className,
      icon,
      TablerIcon,
      style = {},
      iconSize = 18,
      isLoading,
      onPress,
      color,
      buttonVariant,
      href,
      scroll,
      prefetch,
      type,
      disabled,
      target,
      tabIndex,
      size,
      autoFocus,
      'aria-label': ariaLabel
    },
    ref
  ) => {
    const variantClassNames = getVariantClassNames(buttonVariant);
    const sizeClassNames = getSizeClassNames(size);

    const buttonStyle = color ? {color, ...style} : style;

    return (
      <ButtonComponentWrapper
        ref={ref}
        href={disabled ? undefined : href}
        autoFocus={autoFocus}
        target={target}
        onPress={isLoading || disabled ? undefined : onPress}
        type={type}
        scroll={scroll}
        prefetch={prefetch}
        disabled={disabled}
        tabIndex={tabIndex}
        aria-label={ariaLabel}
        className={twMerge(
          'h-button relative overflow-hidden flex shrink-0 items-center justify-center border-none rounded-full font-semibold py-3 no-underline select-none leading-none data-focus-visible:ring-offset-0 data-focus-visible:ring-2 data-focus-visible:ring-gray-200 data-pressed:brightness-90 transition duration-100',
          disabled ? 'opacity-75 cursor-not-allowed' : 'cursor-pointer',
          variantClassNames,
          sizeClassNames,
          className,
          isLoading && '[&>*:not(.loading)]:opacity-0! text-transparent!'
        )}
        style={buttonStyle}
      >
        {icon || TablerIcon ? (
          <div className={twMerge('icon-wrapper -ml-[2px]', children || label ? 'mr-2' : '-mr-[2px]')}>
            <RemixIcon icon={icon} TablerIcon={TablerIcon} size={iconSize} color={color || 'currentColor'} />
          </div>
        ) : null}

        {children || label}

        {isLoading ? (
          <SmallLoader
            className="loading absolute"
            color={color || (buttonVariant === 'primary' ? 'white' : undefined) || 'var(--text-color)'}
          />
        ) : null}
      </ButtonComponentWrapper>
    );
  }
);

export default BaseButton;
