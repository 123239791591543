import {twMerge} from 'tailwind-merge';
import type {TablerIcon} from '@tabler/icons-react';

type Props = {
  icon?: any;
  TablerIcon?: TablerIcon;
  style?: React.CSSProperties;
  color?: string;
  className?: string;
  size?: number;
};

const RemixIcon = ({style, icon, TablerIcon, color, className, size}: Props) => {
  if (TablerIcon)
    return (
      <TablerIcon
        className={twMerge('h-icon shrink-0 [&>svg]:w-full [&>svg]:h-full', className)}
        color={color}
        style={style}
        size={size}
      />
    );

  const sizeStyle = size ? {width: size, height: size} : null;

  const IconComponent = icon;

  return (
    <div
      style={{fill: color, ...sizeStyle, ...style}}
      className={twMerge(
        'w-[24px] h-[24px] h-icon shrink-0 leading-none [&>svg]:fill-inherit [&>svg]:w-full [&>svg]:h-full',
        className
      )}
    >
      <IconComponent />
    </div>
  );
};

export default RemixIcon;
