import {twMerge} from 'tailwind-merge';
import styles from './SmallLoader.module.css';

const dotClassName = 'absolute top-[2px] w-[6px] h-[6px] rounded-full bg-current';

type Props = {
  color?: string;
  className?: string;
};

const SmallLoader = ({color, className}: Props) => {
  return (
    <div
      className={twMerge('inline-block relative w-[40px] h-[10px] mr-[6px] text-(--gray-text)', className)}
      style={{color}}
    >
      <div className={twMerge(dotClassName, styles.loaderDotOne)} />
      <div className={twMerge(dotClassName, styles.loaderDotTwo)} />
      <div className={twMerge(dotClassName, styles.loaderDotThree)} />
      <div className={twMerge(dotClassName, styles.loaderDotFour)} />
    </div>
  );
};

export default SmallLoader;
